<template>
  <div class="news_detail">
    <a-spin :spinning="loading">
      <div class="title">
        {{ detail.title }}
      </div>
      <div class="tips">
        <div class="info">
          作者：<b>{{ detail.newsAuthor }}</b> 发布：<b>{{
            detail.createTime
          }}</b>
          来源：<b>{{ detail.source }}</b>
        </div>
        <div class="share"></div>
      </div>
      <div class="content" v-html="detail.content"></div>
    </a-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/news";
export default {
  data() {
    return {
      detail: null,
      loading: false,
    };
  },
  components: {},
  created() {
    Api.click(parseInt(this.$route.params.id));
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      Api.detail(this.$route.params.id).then((res) => {
        this.loading = false;
        this.detail = res.result;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  color: #333;
  line-height: 2em;
}
.tips {
  padding: 15px 0;
  border-bottom: 1px solid #e1e1e1;
  color: #808080;
  b {
    color: #008840;
    font-weight: normal;
  }
}
.content {
  padding: 10px;
  line-height: 2em;
  margin-bottom: 2em;
}
</style>
